// TODO: Refactor against admin-frontend/auth.service.ts
/*
AuthService

- Manages authentication thru the firebase-auth library (wrapped by AngularFireAuth)
- Session persistence:
 - firebase-auth has his own mechanism of persisting credentials across page
   reloads (the exact mechanism used is unspecified, it can be localStorage, indexedDB,
   sessionStorage, cookies, etc). Those credentials are implicitely loaded on library 
   initialization.
    - See https://firebase.google.com/docs/auth/web/auth-state-persistence
 - AuthService also needs to persist additional session info, as the sessionType,
   gid used for anonymous logins, etc.

- firebase-auth caveats:
 - Using auth.currentUser is not safe on initialization, as it can be null while 
   the library is still initializing from saved credentials. See:
  - https://github.com/firebase/firebase-js-sdk/issues/462
  - https://github.com/firebase/firebase-js-sdk/issues/462#issuecomment-619957604
  - https://medium.com/firebase-developers/why-is-my-currentuser-null-in-firebase-auth-4701791f74f0
  - https://stackoverflow.com/questions/39231344/how-to-wait-for-firebaseauth-to-finish-initializing
  - https://github.com/firebase/firebase-js-sdk/issues/462#issuecomment-735919541
  - This is solved by not relying on currentUser and using onAuthStateChanged instead.
  - Newer library versions have an authStateReady() method, but his implementation
    seems to be similar as using the onAuthStateChanged callback.

- TODO: 
 - Rename to AuthNService to use 'AuthN/AuthZ' unambigous nomenclature.
 */
// dep
import { Injectable, NgZone, isDevMode } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';

// app
import { UserService } from './user.service';
import { Messages } from '../constants/messages';
import { STORAGE_ALL_KEYS, STORAGE_EXTERNAL_GRADE_ID, STORAGE_IS_IMPERSONATING, 
         STORAGE_MAPLABS_SESSION, STORAGE_SESSION, STORAGE_SESSION_EXTERNAL } from '../constants/session';
import Group from '../constants/firestore/group';
import User from '../constants/firestore/user';
import { VerificationEmailService } from '../services/verification-email.service';
import { PaymentsService } from "./payments.service";
import { ModalService } from "./modal.service"
import { environment as ENV } from '@environment';
import { AuthProxyService } from './auth.proxy.service'
import { HEADERS_NO_AUTH, MAIL_EXTERNAL_GRADE, MAIL_ANONYMOUS } from '../constants/auth'
import { BROWSER_DOMAIN, isRunningEmbedded, makeOpenPromise} from '../helpers/utils.helpers';
import { SessionTraceService } from './session-trace.service';
import { SessionService, IAuthSession } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * The JWT token generated by firebase-auth on sucessfull authentication,
   * will be sent to the backend on every request on the 'Authorization'
   * HTTP header.
   */
  private _accessToken : string | null = null 

  /**
   * Application specific session info that should be persisted across
   * page reloads.
   */
  private _authSession : IAuthSession | null = null

  private _isFbInitialized = false;
  private _isFbInitializedP = makeOpenPromise<void>()
  private _signOutCalled = false;
  public redirectUrl: string | null = null;

  constructor(
    // dep
    private _afAuth: AngularFireAuth,
    private _router: Router,
    private _http: HttpClient,
    private _ngZone: NgZone,
    // app
    private _sessionS : SessionService,
    private _userService: UserService,
    private _verificationService: VerificationEmailService,
    private _authProxyService: AuthProxyService,
    private _paymentsService: PaymentsService,
    private _modalService : ModalService,
    private _sessionTraceService : SessionTraceService
  ) {
    /// 1- Migrate old localStorage persistent session data to the new format
    // TODO: Remove after quarantine period
    this._authSessionMigrateV0ToV1();

    /// 2- Subscribe to Firestore Auth state changes
    // "
    // onAuthStateChanged():
    //   Prior to 4.0.0, this triggered the observer when users were signed in, signed out, 
    //   or when the user's ID token changed in situations such as token expiry or password change. 
    //   After 4.0.0, the observer is only triggered on sign-in or sign-out. 
    // " 
    // When firebase-auth initializes, executes this callback with the following values:
    //  - null if there weren't any persistent session saved (or if it fails to load?)
    //  - not-null if a persistent session was loaded
    //  - it seems is also ensured that there will be no race condition on when we setup
    //    this callback, as the observer will be called at least once (ReplaySubject like behaviour),
    //    see https://github.com/firebase/firebase-js-sdk/issues/462#issuecomment-619957604
    // So is possible to use this callback to await for lib initialization, as the first
    // value received is always the resultant from the persistent session loading process
    // at initialization. After that, a null value will mean an user logout.
    //
    // this.afAuth.authState.pipe(
    this._afAuth.auth.onAuthStateChanged(
      async (userFb) => {

        // It was observed that at this point sometimes this._router.url and this._route.snapshot.url 
        // are equal to '/'. Maybe a services initialization race condition? Weird, because
        // here router should be fully initialized. Changed to window.location.
        // '/' ? Services initialization race condition? Changed to window.location.pathname

        // const segments = this._route.snapshot.url.map(segment => segment.path);
        // const url = '/' + segments.join('/');
        // const {url} = this._router // this._route.snapshot
        const url = window.location.pathname;

        if(userFb?.email === MAIL_ANONYMOUS && !(url.startsWith('/widget') ||
                                                 url.startsWith('/report') || 
                                                 url.startsWith('/reports'))) {
          console.error('Unauthorized anonymous login to '+url);
          await this.signOut();
          return;
        }

        if(isRunningEmbedded() && !this._isFbInitialized) {
          // Don't continue the auth steps if the app is running inside
          // an iframe (only review widget)
          this._isFbInitialized = true;
          this._isFbInitializedP.reject("Running embedded, Auth will never be initialized");
          return;
        }

        // console.debug('initSession: onAuthStateChanged:', user);
        if(!this._isFbInitialized) {
          // firestore-auth finished his initialization by loading (or not) his persistent session
          if(!userFb) {
            // No fb persistent session found, so any extra session data is not valid anymore.
            // Delete it just in case.
            this._authSessionDelete();
          } else {
            // Has an fb persistent session, initSession will try to load the app specific 
            // persistent session data and will signOut() if not present. 
            await this.initSession();
          }

          this._isFbInitialized = true; 
          this._isFbInitializedP.resolve();
        } else if(!userFb) {
          // logout detected
          await this.signOut();
        } else if(this._authSession) {
          console.error('User changed after login? should not happen', userFb);
        } else {
          // ignore
        }  

        // else if (this.session.gid) {
        //   this._checkUser(this.session.gid, this.session.uid);
        // }
        // this.forceAuthRefresh()
        // user.getIdToken(true).then(token => {
        //   BAD: This changed epheremal session getter object with no real effect
        //   this.session?.authToken = token;
        // });
      }
    );

    /// 3- Subscribe to Firestore Auth token changes (token refreshes)
    // "
    // onIdTokenChanged():
    //  Adds an observer for changes to the signed-in user's ID token, which includes sign-in, 
    //  sign-out, and token refresh events. This method has the same behavior as 
    //  firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0.
    // "
    // Also check:
    //  - https://github.com/angular/angularfire/issues/2694#issuecomment-734052171
    //
    this._afAuth.auth.onIdTokenChanged(async (user) => {
      // Update the accessToken for events not triggered by forceAuthRefresh
      // (e.g., when firebase-sdk refreshes it automatically in one of his non
      // angular intercepted queries)
      this._setAccessToken(user ? await user.getIdToken() : null, 'onIdTokenChanged')
    })

    /// 4- Subscribe to storage events from other tabs (logouts)
    window.addEventListener('storage', ev => this._onStorageEventFromOtherTab(ev))

    // Now firestore-auth should trigger the next init steps by executing the
    // onAuthStateChanged callback
  }
 
  hasAuthSession() : boolean {
    return this._isFbInitialized
  }

  /**
   * Returns the current AuthSession if any
   * - Even if this is present, maybe the SessionService session was not 
   *   initialized yet. But waiting this is ensured to finish after
   *   service initialization, even with a null result, while waiting 
   *   for the SessionService session only finishes with a valid session 
   *   (so maybe never). 
   */ 
  async waitAuthSession() : Promise<IAuthSession | null> {
    await this._isFbInitializedP;
    return this._authSession;
  }

  private _getHeaders() : { headers : HttpHeaders } {
    // TODO: Horrible, use only token, don't rely on http-headers for 
    // authentication
    const s = this._authSession
    if(!s) {
      // should not happen
      const m = 'Authentication headers requested before authSession initialization';
      console.error(m);
      throw Error(m);
    }

    return { headers : new HttpHeaders(
              { gid : s.gid, 
                uid : s.uid,
                'Authorization' : 'Bearer ' + this._accessToken,
                // Note ENV.apiUrl is changed after domain is fetched
                // TODO: Really needed? X-Domain will be added by HTTP interceptor
                'domain' :  ENV.apiUrl 
              }) }
  }

  async initSession(): Promise<void> {                                                            
    console.debug(`initSession`);
    const userFb = this._afAuth.auth.currentUser;
    if(!userFb) {
      // Must never happen
      console.error('initSession: Firebase not authenticated but initSession called');
      await this.signOut();
      return; // never reached 
    }

    // At this point is ensured the session is authenticated correctly against Firebase Auth

    // Load the app specific persistent session data from a previous time or the session
    // just recently saved after a signIn.
    const authSession = this._authSessionLoad();
    if(!authSession) {
      // initSession called without app-specific authSession saved on storage.
      //
      // This should not happen:
      //   - If initSession is executed as part of a normal login process, then the 
      //     authSession is always saved before calling initSession.
      //   - If the authSession was persisted on a previous run, then the authSession 
      //     will be available on storage in this run. 
      // Possible scenarios:
      //  - The authSession was deleted from storage but firebase-js still has his 
      //    credentials persisted, so on initialization it loads them and calls initSession,
      //    assuming that the authSession will be available on storage.
      //    This happened on cypress tests scenarios that cleared localStorage but not 
      //    indexedDB.
      // 
      console.error('initSession: Firebase is authenticated but no authSession available on localStorage', userFb);
      await this.signOut();
      return; // never reached
    } else if(authSession.uid !== userFb.uid) {
      // Must never happen
      console.error(`initSession: invalid authSession.uid (${authSession.uid}) !== userFb.uid (${userFb.uid})`)
      await this.signOut();
      return; // never reached 
    }
    this._authSession = authSession;

    this._sessionS.onLogin(authSession);

    // SessionService will fetch the user details
    const session = await this._sessionS.waitSession()

    this._sessionTraceService.setEnableGtag(ENV.ga4Enabled);

    const {user, group, features, subscription: sub} = session;

    // TODO: Replace 'bento' feature with a generic 'userGuides' after MAP-2240 deployment
    this._sessionTraceService.setEnableUserGuiding(ENV.userGuidingEnabled && (features.userFeatures.bento || 
                                                                              features.generalFeatures.bento));

    let userCreatedAt: string;
    try {
      const d = (user.createdAt as any);
      userCreatedAt = new firebase.firestore.Timestamp(d.seconds, 
                                                       d.nanoseconds).toDate().toISOString();
    } catch {
      // Anonymous case
      userCreatedAt = "2024-01-01T00:00:00.000Z";
    }
                                                                                                                                                      
    this._sessionTraceService.onLogin(user.uid, {
      domain            : this._sessionS.getDomain().domainName,
      gid               : session.gid,
      email             : user.email,
      name              : user.displayName || user.company || user.email,
      created_at        : userCreatedAt,
      isTrial           : session.isTrial, 
      essentialListings : group.freeLocationsCount     || 0,
      basicListings     : group.basicLocationsCount    || 0,
      ultimateListings  : group.ultimateLocationsCount || 0,
      totalListings     : sub.locationsQty,
   });
  }

  private async _forceAuthRefresh() : Promise<string> {
    // console.log('forceRefresh() start, old token', this.accessToken)
    const user = firebase.auth().currentUser
    if (!user)
      throw Error("No user")

    const token = await user.getIdToken(/*forceRefresh =*/ true)
    // onIdTokenChanged will be called here synchronously

    this._setAccessToken(token, 'forceAuthRefresh')
  
    return token
  }

  //--------------------------------------------------------------------------
  // Session persistence (in addition to the FB Auth persistence mechanism)
  //--------------------------------------------------------------------------

  /**
   * Sets the current AuthSession info and saves it to local storage.
   * - The Firestore session should be already authenticated
   * - This, plus getting the accessToken from Firestore auth, enables
   *   the correct return value of the headers() method.
   */
  private _authSessionSet(s : IAuthSession) {
    if(this._authSession) {
      // Must never happen
      console.error("Trying to set authSession but one already exists!");
      this.signOut();
      return;
    }
    this._authSession = s
    localStorage.setItem(STORAGE_MAPLABS_SESSION, JSON.stringify({ formatVersion : 1, 
                                                                     authSession : s }));
    this._authProxyTryInit();
  }

  /**
   * Migration function to avoid logouting users with old versions. 
   * TODO: Remove after quarantine period.
   */
  private _authSessionMigrateV0ToV1() : void {
    let s : IAuthSession | null = null
    try {
      let json = localStorage.getItem(STORAGE_SESSION_EXTERNAL);
      if(json) {
        const old = JSON.parse(json);
        const externalGradeId = localStorage.getItem(STORAGE_EXTERNAL_GRADE_ID);
        s =  { uid : old.uid, 
               gid : old.gid, 
               sessionType : 'EXTERNAL_GRADER', 
               isImpersonating : false as any,
               externalGradeId };
      } else {
        json = localStorage.getItem(STORAGE_SESSION);
        if(json) {
          const old = JSON.parse(json);        
          const anon = (old?.email === MAIL_ANONYMOUS);
          const isImpersonating = (!anon && !!localStorage.getItem(STORAGE_IS_IMPERSONATING));
          s =  { uid : old.uid, 
                 gid : old.gid, 
                 sessionType : (anon ? 'ANONYMOUS' : 'NORMAL') as any, 
                 isImpersonating : isImpersonating as any };
        }
      }
    } catch(e) {
      console.error("Error migrating session format", e); 
    }

    for(const k of [STORAGE_SESSION_EXTERNAL, STORAGE_EXTERNAL_GRADE_ID, 
                    STORAGE_IS_IMPERSONATING, STORAGE_SESSION]) {
      try {
          localStorage.removeItem(k)
      } catch { 
        //pass
      }
    }

    if(s) {
      localStorage.setItem(STORAGE_MAPLABS_SESSION, JSON.stringify({ formatVersion : 1, 
                                                                       authSession : s }));
    }
  }

  private _authSessionLoad() : IAuthSession | null {
    const json = localStorage.getItem(STORAGE_MAPLABS_SESSION);
    if (!json) 
      return null

    const d = JSON.parse(json); // TODO: validate
    if(d?.formatVersion !== 1)
      return null;

    const s : IAuthSession = d.authSession;
    return s || null
  }

  private _authSessionDelete() : void {
    this._authSession = null
    try {
      localStorage.removeItem(STORAGE_MAPLABS_SESSION)
    } catch {
      // pass
    }
  }

  private _storageRemoveAll() : void {
    console.debug("localStorage: Removing all")
    for(const k of STORAGE_ALL_KEYS)
      try {
          localStorage.removeItem(k)
      } catch { 
        //pass
      }
  }

  //--------------------------------------------------------------------------
  // Login methods
  //--------------------------------------------------------------------------

  private async _assertFbInitAndNoAuth(redirectToLoginWhenFails = true) : Promise<void> {
    const auth = await this.waitAuthSession();
    if(auth) {
      // Must never happen
      const m = "Trying to signIn but already signedIn!"
      console.error(m);
      await this.signOut(redirectToLoginWhenFails);
    }
  }

  // 'NORMAL' sessionType
  async signInWithGooglePopup() : Promise<void> {
    console.debug("signInWithGooglePopup()");

    await this._assertFbInitAndNoAuth();

    const provider = new firebase.auth.GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' })   
    const userCred = await this._afAuth.auth.signInWithPopup(provider);
    if (userCred?.additionalUserInfo?.isNewUser)
      await this._afAuth.auth.currentUser.delete()

    await this._afterNormalLogin(userCred);
  }

  // 'NORMAL' sessionType + isImpersonating == true
  async signInWithImpersonateToken(impersonate_token : string) : Promise<null | string> {
    console.debug("signInWithImpersonateToken()");

    await this._assertFbInitAndNoAuth();

    try {
      const userCred = await this._afAuth.auth.signInWithCustomToken(impersonate_token);
      // We don't need to store the impersonate_token after using it to get the user 
      // credentials, as the Firebase auth lib will store the authentication state
      // behind scenes. 
      await this._afterNormalLogin(userCred, true);
      return null;
    } catch(e) {
      console.debug("error impersonating", e)
      return (e.code + ' ' + e.message)
    }
  }

  // 'NORMAL' sessionType
  async signInWithEmailAndPassword(email : string, password : string) : Promise<void> {
    console.debug("signInWithEmailAndPassword()");

    await this._assertFbInitAndNoAuth();

    const userCred = await this._afAuth.auth.signInWithEmailAndPassword(email, password);
    await this._afterNormalLogin(userCred)
  }

  // 'ANONYMOUS' sessionType
  async signInAnonymously(gid : string) : Promise<void> {
    console.debug("signInAnonymously()");

    await this._assertFbInitAndNoAuth(false);

    // Anonymous user, created on Firebase Auth with uid=WVExQ6iT4KhiHBxfg599TDgfFkJ2
    // Session will use that uid plus the gid passed
    // Notes:
    //  - This anonymous user doesn't belong to any group
    //  - This is not a real FB Auth anonymous login (as provided by auth.signInAnonymously()),
    //    but uses a custom user (TODO: Why was done that way? Change to real anonymous). See
    //    https://firebase.google.com/docs/auth/web/anonymous-auth
    const userCred = await this._afAuth.auth.signInWithEmailAndPassword(MAIL_ANONYMOUS, 'password');
    this._authSessionSet({uid : userCred.user.uid, 
                          gid,
                          sessionType : 'ANONYMOUS',
                          isImpersonating : false
                         });
    await this.initSession();
  }

  // 'EXTERNAL_GRADE' sessionType
  /**
   * gid and uid will be the ones of the single external grade user
   * TODO: Is the external grade user really necesary? If not use anonymous user.
   */
  async signInExternalGradeUser(externalGradeId : string) : Promise<User> {
    console.debug("signInExternalGrader()");

    await this._assertFbInitAndNoAuth(false);

    // TODO: Remove, we don't need to create the user as it already exists. 
    // let userCred : firebase.auth.UserCredential
    // try {
    //   // External grade user, created on Firebase Auth with uid=FOmUvwUao9QZbQxn2df96YnVJ5j2
    //   userCred = await this._afAuth.auth.signInWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external');
    // } catch(err) {
    //   // This code is never reached, as it was used a single time to create the MAIL_EXTERNAL_GRADE
    //   // user on Firebase Auth.
    //   if (err.code === 'auth/user-not-found') {
    //     userCred = await this._afAuth.auth.createUserWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external');
    //   } else {
    //     throw(err);
    //   }
    // }
    // const user = (await this._userService.getUserByUid(userCred.user.uid)) ||
    //              // Same as before, only used to create the MAIL_EXTERNAL_GRADE user at first time:
    //              (await this._createUserAndGroup(userCred, 'external-grade', 'maplabs.com'));

    // External grade user on Firebase Auth (uid=FOmUvwUao9QZbQxn2df96YnVJ5j2 gid=KuofGu7jxUegiOlElVYd)
    const userCred = await this._afAuth.auth.signInWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external');
    const user     = await this._userService.getUserByUid(userCred.user.uid);

    this._authSessionSet({uid : user.uid, 
                          gid : user.gid,
                          sessionType : 'EXTERNAL_GRADER',
                          isImpersonating : false,
                          externalGradeId
                          });
    await this.initSession();
    // localStorage.setItem(STORAGE_SESSION_EXTERNAL, JSON.stringify(user));
    // try {
    //   user.authToken = await this.afAuth.auth.currentUser.getIdToken(true)
    // } catch(e) {
    // }
    return user
  }

  /**
   * Creates an account on Firebase Auth using a Google Login popup
   * Here the UID is created
   */
  async registerWithGoogleAndSignIn() : Promise<void> {
    console.debug("registerWithGoogleAndSignIn()");

    await this._assertFbInitAndNoAuth();
  
    // This will open a Google Login Popup and the user will be created on Firebase Auth if it doesn't
    // exists.
    const newUserCred = await this._afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    if(!newUserCred.additionalUserInfo.isNewUser)
      throw 'This account is already registered. Try logging in.'

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const displayName = (newUserCred.additionalUserInfo.profile as any)?.name
      if(displayName)
        await this._afAuth.auth.currentUser.updateProfile({ displayName })
    } catch(e) {
      console.error("Error updating new user profile:", e)
    }

    const {uid, gid} = await this._createUserAndGroup(newUserCred);
    this._authSessionSet({ uid, 
                           gid, 
                           sessionType : 'NORMAL', 
                           isImpersonating : false });                         
  }
  
  //--------------------------------------------------------------------------
  
  async signOut(redirectToLogin = true) : Promise<void> {
    await this._ngZone.run(async () => {
      console.debug(`signOut(): redirectToLogin=${redirectToLogin}`);
      if(this._signOutCalled) {
        // Avoid dup-call from firebase-auth callbacks
        console.debug('signOut(): already called');
        return
      }
      this._signOutCalled = true;

      for(const f of [(() => this._sessionTraceService.onLogout()),
                      (() => this._storageRemoveAll()),
                      (() => this._afAuth.auth.signOut())] as const)
        try {
          await f()
        } catch (e) {
          console.error("signOut(): finalizer error", e)
        }

      if(redirectToLogin) {
        console.debug('signOut(): reload to /login');
        await this._router.navigate(['/login'], { replaceUrl: true });
      } else {
        // Ensure refresh on logout to mitigate memory leaks and oversubscribed observers
        // If we don't that, some observers still run and try to make http requests
        // without valid authorization headers
        // Also for code simplification purposes we dont want SessionServers subscribers 
        // to support a transition to session=null.
        console.debug('signOut(): reload to same url');
      }
      // Ensure page reload no matter the RouteReuseStrategy
      window.location.reload();
    })
    this.redirectUrl = null;
  }

  /**
   * Create group and user with gid and get of firebase token id
   * @param newUserCred  Response firestore 
   * @param displayName  name for user
   * @param company  company for user
   */
  private async _createUserAndGroup(newUserCred : firebase.auth.UserCredential, displayName = null, 
                                    company = '', needsVerification = false) : Promise<{uid : string, gid : string}> {
    const now = new Date()

    const {domainName} = this._sessionS.getDomain();
    const authUser = newUserCred.user;
    const name = authUser.displayName || displayName;

    // Firebase Auth creates the UID
    const uid  = authUser.uid

    // Generate the GID and create the Group
    const {gid} = await this._createGroup({ users: [uid],
                                            admin: uid,
                                            domain: domainName,
                                            company})
    const user : User = {
      uid,
      gid,
      email : authUser.email,
      displayName : name,
      company,
      photoURL : authUser.photoURL,
      lastLogin: firebase.firestore.Timestamp.fromDate(now),
      createdAt: now,  // FIXME: Browser local time but must be server UTC time. 
      timezone : now.getTimezoneOffset(),
      registrationDomain: domainName,
      domainSurfing: false,
      isAI: false,
      isActive: true,
      // First user created in group will be his admin 
      role : 'admin'
    }

    // TODO: needsVerification is always false on all call sites, remove it?
    if (needsVerification) {
      user.emailVerified         = null
      user.emailVerificationHash = (new Md5()).appendStr(`${authUser.uid}${user.gid}${user.displayName}`).end() as string
    }

    // TODO: At this point the SessionService was not yet initialized, now it doesn't 
    // matter because this creates the user directly on Firestore and doesn't need it,
    // but check out for that after changing this to a backend enpdoint. 
    await this._userService.save(user)

    ///// TODO: Refactor against session callbacks
    // if(!needsVerification) {
    //   // if (this.session?.gid) {
    //   //   this._checkUser(this.session?.gid, this.session?.uid);
    //   // }
    //   this._setAccessToken(await this.afAuth.auth.currentUser.getIdToken(true), "createUserAndGroup")
    // }
    ///// 

    return {uid, gid};
  }

  // TODO: move to navigationService (maybe without alertIfNotPaymentMethod)
  async redirectAfterLogin() : Promise<void> {
    const {isMember, gid} = this._sessionS.getSession();
    if (isMember) {
      await this._router.navigate([this.redirectUrl || '/accounts'])
    } else
      try {
        // TODO: Replace call with AccountService.getAccountPaginate, now is not used because if AccountService is
        // imported from this file then a circular dep will be created.
        const r = await this._http.get(`${ENV.apiUrl}/v2/accounts/${gid}/all?page=1&pageSize=25&accountIds=`).toPromise();

        const accounts = r["items"]
        const url = this.redirectUrl ? this.redirectUrl : accounts.length ? `accounts/${accounts[0].accountId}/locations` : '/accounts'
        await this._router.navigate([url])
        await this._alertIfNotPaymentMethod()
      } catch (err) {
        console.error(err)
      }
  }

  /**
   * Executed after the user logins on a NORMAL session.
   * Note, this is not executed when a NORMAL persistent session is recovered.
   */
  private async _afterNormalLogin(userCred: firebase.auth.UserCredential, isImpersonating = false) {
    console.debug('_afterNormalLogin()');

    const {uid} = userCred.user;
    const user = await this._userService.getUserByUid(uid);
    if(!user) {
      await this._modalService.openErrorModal('Heads up', Messages.register.USER_DOESNT_EXIST)
      await this.signOut();
      return // never reached, as signOut reloads the page
    } 

    this._authSessionSet({uid,
                          gid : user.gid, 
                          sessionType : 'NORMAL',
                          isImpersonating : isImpersonating as any });

    const domain = this._sessionS.getDomain();
    await this._userService.updateLastLogin(user.gid, user.uid, new Date(), domain.xDomainName);

    const isEmailVerified = await this._userService.getEmailIsVerified(user)

    if (isEmailVerified) {
      // TODO: Why this is not also called when email is not verified?
      await this.logoutIfDomainValidationFails(user);
    } else {
      const verif = await this._verificationService.getVerification(user.uid, user.gid).toPromise()
      if(verif.docs.length) {
        const data = verif.docs[0].data();
        if (data.emailVerified == null) {
          await this._modalService.openErrorModal('Heads up', Messages.register.EMAIL_NOT_VERIFIED)
          await this.signOut();
          return // never reached, as signOut reloads the page
        } else {
          // TODO: Should be done server-side!
          await this._userService.updateUser(user.gid, user.uid, { emailVerified : firebase.firestore.Timestamp.now()});
          await this._sessionS.refresh();
        }
      }
    }

    // if (this.session?.gid)
    //   this._checkUser(this.session.gid, this.session.uid);

    await this.initSession();
    // this._showMessageTypeAuth(this.session)
    await this.redirectAfterLogin()
  }

  public async logoutIfDomainValidationFails(user : User) : Promise<void> {
    // TODO: Fetch this from session service and check on an external session observer like PaywallService
    const domVal = await this._userService.domainValidation(BROWSER_DOMAIN.domainName, user.gid, user.uid, user.domainSurfing);
    // The isDevMode flag should be present here to enable impersonation during development.
    const desc = `Domain [${BROWSER_DOMAIN.domainName}], registration domain [${domVal.domain}]`

    // The isDevMode flag should be present here to enable impersonation during development.
    if (isDevMode()) {
      console.debug(`Domain validation Ignored: DevMode=true, `+desc);
    } else if (!domVal.allowLogin) {
      console.debug("Domain validation FAILED")

      await this._modalService.openErrorModal('Heads up', "Sorry, we couldn't find your account. " +
                                                          "Please check your username and password or contact support.")                    
      await this.signOut() 
      return // never reached, as signOut reloads the page
    } 
  }


  private _createGroup(group: Group) : Promise<{ gid : string}> {
    return this._http.post<any>(`${ENV.apiUrl}/v2/auth/signup`, group, HEADERS_NO_AUTH).toPromise()
  }

  private _setAccessToken(accessToken : string | null, debugStr : string) {
    this._accessToken = accessToken;
    console.debug(`${debugStr}: accessToken changed to:`, accessToken ? accessToken.substring(0, 10) + "..." : 'null');
    this._authProxyTryInit();
  }

  private _authProxyTryInit() {
    // Await until both _accessToken and _authSession are ready, as both
    // are required by the headers() method, that is ensured to be called
    // only after authProxyService is initialized
    if(!this._authProxyService.initialized && this._accessToken && this._authSession)
      this._authProxyService.initialize(() => this.signOut(), 
                                        () => this._getHeaders(), 
                                        () => this._forceAuthRefresh());
  }

  /**
   * Alert the user if he has a paid Subscription and no Credit Card configured
   */
  private async _alertIfNotPaymentMethod(): Promise<void> {
    const s = await this._sessionS.waitSession()

    if(!s.isTrial && 
       s.requiresPaymentMethod &&
       (s.group.basicLocationsCount || s.group.ultimateLocationsCount) &&
       !(await this._paymentsService.hasPaymentMethods(s.gid))) {
      
         await this._modalService.openErrorModal('Heads up',
                                                 "You don't have a Credit Card set up. Please add one.")
      } 
  }

  private _onStorageEventFromOtherTab(event : StorageEvent) : void {
    // console.debug('storage', event)
    if(event.key === STORAGE_MAPLABS_SESSION && (!event.newValue || !JSON.parse(event.newValue))) {
      console.debug('Detected signOut from another tab')
      this.signOut()
    }
  }

}